<template>
    <v-card flat>
        <v-card-title class="pl-0">Instellingen</v-card-title>
        <v-divider />
        <PromiseContainer :promises.sync="promises">
            <v-list class="pa-0">
                <ParameterListItem v-for="parameter in getParameters" :key="parameter.name" :parameter="parameter" />
            </v-list>
        </PromiseContainer>
    </v-card>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import ParameterListItem from "@/components/settings/parameter/ParameterListItem.vue";
import { translateParameterName } from "@/shared/utils/translateUtils";

export default {
    name: "Parameters",
    components: {
        ParameterListItem,
        PromiseContainer,
    },
    data() {
        return {
            modifyingParameter: false,
            parameter: null,
            translateParameterName,
            promises: [this.$store.dispatch('parameterModule/fetchParamsOrganization')]
        }
    },
    computed: {
        getParameters() {
            let sortedParameters = this.$store.getters["parameterModule/getParameters"];
            sortedParameters.sort(function (a, b) {
                return translateParameterName(a.name).localeCompare(translateParameterName(b.name))
            })
            return sortedParameters
        }
    }
}
</script>

<style scoped></style>