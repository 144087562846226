<template>
  <v-row dense no-gutters class="paramRow">
    <v-col :cols="mobile ? 7 : 10" class="flex-grow-0 align-self-center">
      <span class="body-1 font-weight-medium text-xs-right d-flex ">
        <span class="text-truncate ">{{ getParameterName }}</span>
        <ToolTipParameterDescription :parameterName="parameter.name" />
      </span>
    </v-col>
    <v-col :cols="mobile ? 5 : 2" dense align="right" class="flex-grow-0 ">
      <ParameterInput v-model="parameter" class="">
      </ParameterInput>
    </v-col>
  </v-row>
</template>

<script>
import { translateParameterName } from "@/shared/utils/translateUtils"
import { translateParameterNameMobile } from "@/shared/utils/translateUtils"
import ToolTipParameterDescription from "@/components/shared/actions/ToolTipParameterDescription";
import ParameterInput from "./ParameterInput.vue";

export default {
  name: "ParameterListItem",
  components: { ToolTipParameterDescription, ParameterInput },

  props: ['parameter'],
  methods: {
    modifyParameter() {
      this.$emit('modify', this.parameter)
    },
    firstLetter(name) {
      return name?.charAt(0)
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    getParameterName() {
      if (this.mobile) {
        return translateParameterNameMobile(this.parameter.name)
      } else {
        return translateParameterName(this.parameter.name)
      }
    },
  }
}
</script>
<style>
.paramRow {
  height: 48px;
}

.paramRow+.paramRow {
  border-top: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
}
</style>