<template>
  <v-text-field dense v-if="getType === parameterOption.INTEGER" type="number" :value="getValue"
    :rules="getParameterRules" required autofocus @input="setValue" hide-details="auto" />
  <v-row no-gutters dense v-else-if="getType === parameterOption.BOOLEAN">
    <v-spacer></v-spacer>
    <v-col dense class="d-flex flex-grow-0 flex-shrink-1 align-center">
      <v-switch dense class="d-flex justify-end mt-2" hide-details="auto" :input-value="getValue"
        :rules="getParameterRules" required autofocus @change="setValue" />
    </v-col>
  </v-row>
  <v-autocomplete dense v-else-if="getType === parameterOption.OPTIONS" :value="getValue" :items="this.value.options"
    @change="setValue" hide-details="auto" />
  <TimePicker dense v-else-if="getType === parameterOption.TIME" :value="getValue" required @input="setValue"
    hide-details="auto" />
  <v-text-field dense v-else type="text" :value="getValue" :rules="getParameterRules" required autofocus @input="setValue"
    hide-details="auto" />
</template>

<script>
import ParameterOption from "@/shared/constants/ParameterOption";
import TimePicker from "@/components/shared/fields/TimePicker.vue";
import { format } from "date-fns";
import { parseStringToDate } from "@/shared/utils/dateUtils";

export default {
  name: "ParameterInput",
  components: { TimePicker },
  props: {
    value: Object,
  },
  data() {
    return {
      parameterOption: ParameterOption,
    }
  },
  methods: {
    setValue(value) {
      // TODO Might wanne add debounce since it will automatically send requests every time the value is changed.
      switch (this.getType) {
        case ParameterOption.TIME: {
          // convert Date object (received from TimePicker) to String time value (ex. 09:00)
          this.value.value = format(value, "HH:mm")
          this.getUpdatedParameter()
          break
        }
        default:
          this.value.value = value;
          this.getUpdatedParameter()
      }
      this.$emit('input', this.value)
    },
    getUpdatedParameter() {
      this.$store.dispatch('parameterModule/updateParameter', JSON.parse(JSON.stringify(this.value)))
    }
  },
  computed: {
    getParameterRules() {
      switch (this.getType) {
        case ParameterOption.INTEGER:
          return [v => v >= 0 || 'Waarde moet groter zijn dan 0']
        case ParameterOption.BOOLEAN:
          return []
        default:
          return [v => !!v || 'Waarde moet ingevuld worden']
      }
    },
    getType() {
      return this.value.type.toUpperCase()
    },
    getValue() {
      switch (this.getType) {
        case ParameterOption.BOOLEAN:
          return this.value.value === 'true'
        case ParameterOption.TIME: {
          return parseStringToDate(this.value.value)
        }
        default:
          return this.value.value
      }
    },
  }
}
</script>

<style scoped>
.v-text-field>>>input {
  text-align: end !important;
}
</style>