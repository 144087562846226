<template>
  <BasicToolTip :description="getParameterDescriptionByName(parameterName)"/>
</template>

<script>
import { getParameterDescriptionByName} from "@/shared/utils/translateUtils"
import BasicToolTip from "@/components/shared/actions/BasicToolTip"

export default {
  name: "ToolTipParameterDescription",
  components: {
    BasicToolTip,
  },
  props:{
    parameterName: null,
  },
  methods: {
    getParameterDescriptionByName,
  }
}
</script>
